import { strippedHtml } from "client-server-shared/utils/text-utils";

export const buildJsonLd = (faq: { question: string; answer: string }[]) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faq.map((item) => {
      return {
        "@type": "Question",
        name: item.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: strippedHtml(item.answer),
        },
      };
    }),
  };
};

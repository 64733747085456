import MuiGrid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import { getStyleProps } from "modules/themes/styleUtils";
import React from "react";
type GridProps = Grid2Props;
export { GridProps };
export const Grid = React.forwardRef((props: Grid2Props, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiGrid {...rest} ref={ref} {...getStyleProps(rest)}>
      {children}
    </MuiGrid>
  );
});

Grid.displayName = "Grid";

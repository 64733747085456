import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails, {
  AccordionDetailsProps,
} from "@mui/material/AccordionDetails";
import { ExtendedStyleProps } from "modules/themes/custom-types";
import { getStyleProps, passProps } from "modules/themes/styleUtils";
import React from "react";

export type AccordionProps = MuiAccordionProps;

export const Accordion = React.forwardRef(
  (props: AccordionProps & ExtendedStyleProps, ref) => {
    const { children, ...rest } = props;
    return (
      <MuiAccordion {...passProps(rest)} ref={ref} {...getStyleProps(rest)}>
        {children}
      </MuiAccordion>
    );
  }
);

Accordion.displayName = "Accordion";

export type AccordionSummaryProps = MuiAccordionSummaryProps;

export const AccordionSummary = React.forwardRef(
  (props: AccordionSummaryProps & ExtendedStyleProps, ref) => {
    const { children, ...rest } = props;
    return (
      <MuiAccordionSummary
        {...passProps(rest)}
        ref={ref}
        {...getStyleProps(rest)}
      >
        {children}
      </MuiAccordionSummary>
    );
  }
);

AccordionSummary.displayName = "AccordionSummary";

export const AccordionDetails = React.forwardRef(
  (props: AccordionDetailsProps & ExtendedStyleProps, ref) => {
    const { children, ...rest } = props;
    return (
      <MuiAccordionDetails
        {...passProps(rest)}
        ref={ref}
        {...getStyleProps(rest)}
      >
        {children}
      </MuiAccordionDetails>
    );
  }
);

AccordionDetails.displayName = "AccordionDetails";

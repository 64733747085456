import { Box, BoxProps } from "components/ui/box";
import { Typography } from "components/ui/Typography";
import Image from "next/image";
import React from "react";
import { ResponsiveText } from "./shared";
import { AutoPlayVideo } from "components/video/auto-play-video";
import { useTranslation } from "components/translate";

interface StepData {
  steps: {
    type: "image" | "video";
    stepNumber: string;
    headline: string;
    description: string;
    imageUrl: string;
    imageAlt: string;
  }[];
  finalStep: {
    stepNumber: string;
    imageUrl?: string;
    imageAlt?: string;
    headline: string;
    description: string;
  };
}

const StepNumber = ({
  children,
  sx = {},
}: {
  children: React.ReactNode;
  sx: BoxProps;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "24px",
        background: "#28334B",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        width: "40px",
        height: "40px",
        ...sx,
      }}
    >
      <Typography
        color="text.white"
        variant="body1"
        sx={{
          fontSize: "20px",
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

const Stepper = ({
  stepData,
  start = "right",
  title,
}: {
  stepData: StepData;
  start?: "right" | "left";
  title?: string;
}) => {
  const evenDirection = start === "right" ? "row" : "row-reverse";
  const inevenDirection = start === "right" ? "row-reverse" : "row";
  const extraStep = stepData.finalStep ? 1 : 0;
  const { t } = useTranslation("common");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "48px auto",
        }}
      >
        <Typography
          marginBottom={12}
          textAlign="center"
          color="#D4DCE5"
          variant="body2"
        >
          {title || t("HOW IT WORKS")}
        </Typography>
        <ResponsiveText
          fontWeight={500}
          textAlign="center"
          color="text.white"
          fontSize={30}
          variant="h4"
        >
          {t("Get Started with", {
            step: stepData.steps.length + extraStep,
          })}
        </ResponsiveText>
      </Box>

      <Box
        component="ul"
        sx={{
          padding: 0,
          margin: 0,
          listStyle: 0,
          display: "flex",
          flexDirection: "column",
          rowGap: "72px",
        }}
      >
        {stepData.steps.map((step, index) => {
          const isLast = index === stepData.steps.length - 1;
          return (
            <Box
              component="li"
              key={step.headline}
              id={`step${index + 1}`}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                alignItems: {
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                  xl: "flex-start",
                },
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: index % 2 === 0 ? evenDirection : inevenDirection,
                  xl: index % 2 === 0 ? evenDirection : inevenDirection,
                },
                overflow: isLast ? "hidden" : "visible",
                columnGap: "48px",
                rowGap: "24px",
                margin: "0 12px",
              }}
            >
              <Box
                sx={{
                  maxWidth: {
                    xs: "500px",
                    sm: "500px",
                    md: "500px",
                    lg: "400px",
                    xl: "400px",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "12px",
                  }}
                >
                  <StepNumber
                    sx={{
                      position: "relative",
                      marginRight: "12px",
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "flex",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    {step.stepNumber}
                  </StepNumber>
                  {step.headline && (
                    <Typography
                      color="text.white"
                      fontWeight={600}
                      sx={{
                        fontSize: "22px",
                      }}
                      variant="h5"
                    >
                      {step.headline}
                    </Typography>
                  )}
                </Box>

                <Typography
                  color="#D4DCE5"
                  sx={{
                    fontSize: "16px",
                  }}
                  variant="body1"
                >
                  {step.description}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <StepNumber>{step.stepNumber}</StepNumber>
                {isLast && !stepData.finalStep ? null : (
                  <Box
                    sx={{
                      position: "absolute",
                      height: "100%",
                      width: "10px",
                      borderRight: "1px dashed #FFFFFF",
                      marginLeft: "15px",
                    }}
                  />
                )}
              </Box>
              <Box>
                {step.type === "image" ? (
                  <Image
                    src={step.imageUrl}
                    height={400}
                    placeholder="blur"
                    alt={step.imageAlt}
                    style={{
                      height: "100%",
                      objectFit: "contain",
                    }}
                    width={400}
                  />
                ) : null}
                {step.type === "video" ? (
                  <AutoPlayVideo
                    imageUrl={step.imageUrl}
                    width={400}
                    videoUrl={step.videoUrl}
                  />
                ) : null}
              </Box>
            </Box>
          );
        })}
      </Box>
      {stepData.finalStep && (
        <Box
          sx={{
            justifyContent: "center",
            marginTop: "24px",
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
              xl: "flex",
            },
          }}
        >
          <StepNumber>{stepData.finalStep.stepNumber}</StepNumber>
        </Box>
      )}
      {stepData.finalStep && (
        <Box
          sx={{
            display: "flex",
            margin: "48px auto",
            justifyContent: "center",
            columnGap: "48px",
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "column-reverse",
              lg: "row",
              xl: "row",
            },
            alignItems: "center",
            rowGap: "48px",
          }}
        >
          {stepData.finalStep.imageUrl ? (
            <Box>
              <Image
                src={stepData.finalStep.imageUrl}
                placeholder="blur"
                alt={stepData.finalStep.imageAlt}
                style={{
                  height: "100%",
                  objectFit: "contain",
                }}
                width={300}
              />
            </Box>
          ) : null}
          <Box
            sx={{
              border: "1px solid #5B2D75",
              padding: "24px 36px 36px 36px",

              width: "300px",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                marginBottom: "12px",
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <StepNumber>{stepData.finalStep.stepNumber}</StepNumber>
            </Box>
            {stepData.finalStep.headline && (
              <Typography
                color="text.white"
                marginBottom={6}
                fontWeight={700}
                sx={{
                  fontSize: "22px",
                }}
              >
                {stepData.finalStep.headline}
              </Typography>
            )}
            <Typography
              variant="body1"
              sx={{
                color: "#D4DCE5",
                fontSize: "16px",
              }}
            >
              {stepData.finalStep.description}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Stepper;
